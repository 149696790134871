<template>
  <div id="CNCEpidemicPrevention">数控防疫追溯分析</div>
</template>

<script>
export default {
  data() {
      return {

      }
  }
}
</script>

<style lang="scss">
#CNCEpidemicPrevention {
}
</style>
